/**
 * URL to API by convention, using current domain.
 */
function defaultApiUrl(currentHost = location.hostname) {
  const hostParts = currentHost.split(".");
  if (!["develop", "staging", "wms"].includes(hostParts[0])) {
    hostParts.shift();
  }
  hostParts.unshift("api");
  const domain = hostParts.join(".");
  return `https://${domain}`;
}

export const apiUrl = import.meta.env.VITE_API_URL
  ? import.meta.env.VITE_API_URL
  : defaultApiUrl();

export const netsuiteUrl = import.meta.env.VITE_NETSUITE_URL
  ? import.meta.env.VITE_NETSUITE_URL
  : undefined;

export const timeoutForInactivity = import.meta.env.VITE_INACTIVITY_TIMEOUT
  ? parseInt(import.meta.env.VITE_INACTIVITY_TIMEOUT)
  : 10 * 60 * 1000; // 10 minutes

export enum Language {
  NL = "nl",
  EN = "en",
}
