import { defineStore, storeToRefs } from "pinia";
import { ref } from "vue";

export const useDarkMode = defineStore("dark-mode", () => {
  // Note, we're managing 'dark mode' while avoiding browser preferences. users
  // share computers, so to avoid confusing situations they shouldn't also
  // manage dark mode in their browser settings. Instead, they'll just use a
  // toggle, we'll save their preference in local storage so it persist on
  // a refresh.
  const darkMode = ref(localStorage.getItem("darkMode") === "true");
  const setDarkMode = (_darkMode: boolean) => {
    darkMode.value = _darkMode;
    localStorage.setItem("darkMode", _darkMode ? "true" : "false");
    if (_darkMode) {
      document.documentElement.classList.add("dark-mode");
    } else {
      document.documentElement.classList.remove("dark-mode");
    }
  };
  setDarkMode(darkMode.value);

  return { darkMode, setDarkMode };
});

export const useDarkModeManager = () => storeToRefs(useDarkMode());
