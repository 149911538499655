import type {
  LocationQuery,
  NavigationGuard,
  RouteLocationNormalized,
} from "vue-router";
import { useAuth } from "@wovar/common/auth/store.js";

/**
 * Vue router guard, to be used in a `beforeEach`
 *
 * Assumes routes need authentication by default,
 * a route can opt out using meta `allowAnon` boolean propery.
 */
export const authRouteGuard: NavigationGuard = async (
  to: RouteLocationNormalized,
) => {
  if (to.meta.allowAnon) {
    return true;
  }

  const auth = useAuth();
  if (to.path === "/auth/logout") {
    await auth.logoutCallback();
  } else if (hasAuthParams(to.query)) {
    try {
      await auth.loginCallback();
    } catch (e) {
      // Need to implement an error page/view?
      console.error(e);
    } finally {
      return { name: "home" };
    }
  }
  if (auth.isAuthenticated) {
    return true;
  }
  await auth.loginRedirect();
};

const hasAuthParams = (q: LocationQuery): boolean => {
  return !!((q["code"] || q["error"]) && q["state"]);
};
